import { Insight } from '.'

export enum Action {
  Share = 'share',
  Assets = 'load_assets',
  AssetsPagination = 'load_assets_pagination',
  Plan = 'load_plan',
  Geoboundary = 'geoboundary_geometries',
  CustomPOIs = 'custom_POIs',
  Asset = 'asset_details',
  Insights = 'insights',
  PasswordRequired = 'password_required',
  SharedExportPoisAssets = 'shared_export_pois_assets',
  SharedExportAllPois = 'shared_export_all_pois',
  SharedExportAudiencesAssets = 'shared_export_audiences_assets',
}

export interface SharePlanProperties {
  id: string
  password?: string
}

export interface CreateSharedPlanParameters {
  planId: string
  appVersion: string
  password?: string
  assetsOffset?: number
  token?: string
  iv?: string
}

export interface AssetProperties {
  address: string
  external_panel_id: string
  geom: never
  id: string
  internal_panel_id: string
  impressions: number
  latitude: number
  longitude: number
  name: string
  l1_panel_class: string
  l1panelclass_id: number
  l2product_id: number
  l2_product: string
  state_id: number
  dmas_id: number
  congresional_district_id: number
  cbsa_id: number
  county_id: number
  city_id: string
  zip_code_id: string
  zip_code_name: string
  ad_region_text: string
  title: string
  excluded?: boolean
}

export interface GeoJsonAsset {
  type: 'Feature'
  geometry: {
    coordinates: [number, number]
    type: 'Point'
  }
  properties: AssetProperties
}

export interface RemoteAssetResolution {
  count: number
  assets: GeoJsonAsset[]
  token?: string
  iv?: string
}

export interface SharedPlanInsights {
  demographics: Insight.InsightResult[]
  topindex: Insight.InsightResult[]
  overview: Insight.InsightResult[]
  impressions: Insight.InsightImpressionsResult[]
}
